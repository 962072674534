import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box as ChakraBox,
  Flex as ChakraFlex,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

import { IconComp } from '../../components/base/IconComp';
import { LinkType } from '../../lib/enums/LinkType';
import { OverviewItemModel } from '../../utils/types/overviewItemModel';
import { Link } from '../base/Link';

interface OverviewItemProps {
  model: OverviewItemModel[];
}

export const OverviewSpotAutomaticAccordion: FunctionComponent<OverviewItemProps> = ({ model }) => {
  const { common } = withTranslations();
  const length = model.length;
  const columns: any = [];
  const size = Math.floor(length / 3);
  const remainder = length - size * 3;
  let lastInd = 0;
  [...Array(3)].map(() => {
    columns.push(size);
  });
  if (remainder == 1) columns[0] += 1;
  if (remainder == 2) {
    columns[0] += 1;
    columns[1] += 1;
  }
  const router = useRouter();
  return (
    <ChakraFlex direction={{ base: 'column', md: 'row' }}>
      <Accordion allowMultiple={false} allowToggle={true} w='100%'>
        <ChakraFlex direction={{ base: 'column', md: 'row' }} columnGap={{ md: 'l' }}>
          {columns.map((column: any, i: any) => {
            const subpart = model.slice(lastInd, lastInd + column);
            lastInd += column;
            return (
              <ChakraFlex
                key={i}
                direction={{ base: 'column' }}
                columnGap={{ md: 'l' }}
                flexGrow='1'
                flexBasis='min-content'
              >
                {subpart.map((item) => {
                  return (
                    <AccordionItem key={item.id} w='100%'>
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton>
                            <ChakraBox as='span' flex='1' textAlign='left'>
                              {item.headline}
                            </ChakraBox>
                            {isExpanded ? (
                              <IconComp iconName='minus' chakraStyle={{ path: { fill: 'mountain' } }}></IconComp>
                            ) : (
                              <IconComp iconName='plus' chakraStyle={{ path: { fill: 'mountain' } }}></IconComp>
                            )}
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <ChakraBox>
                              {item.text?.map((text, index) => (
                                <Text key={index}>{text.body}</Text>
                              ))}
                            </ChakraBox>
                            <ChakraBox pt='xs'>
                              {item.link && item.linkText && (
                                <Link href={item.link} type={LinkType.Text} locale={router.locale}>
                                  {common(item.linkText)}
                                </Link>
                              )}
                            </ChakraBox>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  );
                })}
              </ChakraFlex>
            );
          })}
        </ChakraFlex>
      </Accordion>
    </ChakraFlex>
  );
};
